:root {
  // Primary
  --vs-primary: var(--vs-primary-500);
  --vs-primary-50: 240 235 250;
  --vs-primary-100: 225 214 245;
  --vs-primary-200: 194 173 236;
  --vs-primary-300: 163 132 226;
  --vs-primary-400: 133 90 216;
  --vs-primary-500: 102 49 206;
  --vs-primary-600: 82 39 165;
  --vs-primary-700: 61 29 124;
  --vs-primary-800: 41 20 83;
  --vs-primary-900: 20 10 41;
  .theme-primary {
    --vs-theme: var(--vs-primary);
    --vs-theme-50: var(--vs-primary-50);
    --vs-theme-100: var(--vs-primary-100);
    --vs-theme-200: var(--vs-primary-200);
    --vs-theme-300: var(--vs-primary-300);
    --vs-theme-400: var(--vs-primary-400);
    --vs-theme-500: var(--vs-primary-500);
    --vs-theme-600: var(--vs-primary-600);
    --vs-theme-700: var(--vs-primary-700);
    --vs-theme-800: var(--vs-primary-800);
    --vs-theme-900: var(--vs-primary-900);
    --vs-theme-parent: var(--vs-primary);
    --vs-theme-parent-50: var(--vs-primary-50);
    --vs-theme-parent-100: var(--vs-primary-100);
    --vs-theme-parent-200: var(--vs-primary-200);
    --vs-theme-parent-300: var(--vs-primary-300);
    --vs-theme-parent-400: var(--vs-primary-400);
    --vs-theme-parent-500: var(--vs-primary-500);
    --vs-theme-parent-600: var(--vs-primary-600);
    --vs-theme-parent-700: var(--vs-primary-700);
    --vs-theme-parent-800: var(--vs-primary-800);
    --vs-theme-parent-900: var(--vs-primary-900);
  }
  .theme-primary-inherit {
    --vs-theme: var(--vs-theme-parent, var(--vs-primary));
    --vs-theme-50: var(--vs-theme-parent-50, var(--vs-primary-50));
    --vs-theme-100: var(--vs-theme-parent-100, var(--vs-primary-100));
    --vs-theme-200: var(--vs-theme-parent-200, var(--vs-primary-200));
    --vs-theme-300: var(--vs-theme-parent-300, var(--vs-primary-300));
    --vs-theme-400: var(--vs-theme-parent-400, var(--vs-primary-400));
    --vs-theme-500: var(--vs-theme-parent-500, var(--vs-primary-500));
    --vs-theme-600: var(--vs-theme-parent-600, var(--vs-primary-600));
    --vs-theme-700: var(--vs-theme-parent-700, var(--vs-primary-700));
    --vs-theme-800: var(--vs-theme-parent-800, var(--vs-primary-800));
    --vs-theme-900: var(--vs-theme-parent-900, var(--vs-primary-900));
  }

  // Accent
  --vs-accent: var(--vs-accent-500);
  --vs-accent-50: 253 232 238;
  --vs-accent-100: 251 209 221;
  --vs-accent-200: 246 162 186;
  --vs-accent-300: 242 115 152;
  --vs-accent-400: 238 68 117;
  --vs-accent-500: 234 22 83;
  --vs-accent-600: 187 17 66;
  --vs-accent-700: 140 13 50;
  --vs-accent-800: 94 9 33;
  --vs-accent-900: 47 4 17;
  .theme-accent {
    --vs-theme: var(--vs-accent);
    --vs-theme-50: var(--vs-accent-50);
    --vs-theme-100: var(--vs-accent-100);
    --vs-theme-200: var(--vs-accent-200);
    --vs-theme-300: var(--vs-accent-300);
    --vs-theme-400: var(--vs-accent-400);
    --vs-theme-500: var(--vs-accent-500);
    --vs-theme-600: var(--vs-accent-600);
    --vs-theme-700: var(--vs-accent-700);
    --vs-theme-800: var(--vs-accent-800);
    --vs-theme-900: var(--vs-accent-900);
    --vs-theme-parent: var(--vs-accent);
    --vs-theme-parent-50: var(--vs-accent-50);
    --vs-theme-parent-100: var(--vs-accent-100);
    --vs-theme-parent-200: var(--vs-accent-200);
    --vs-theme-parent-300: var(--vs-accent-300);
    --vs-theme-parent-400: var(--vs-accent-400);
    --vs-theme-parent-500: var(--vs-accent-500);
    --vs-theme-parent-600: var(--vs-accent-600);
    --vs-theme-parent-700: var(--vs-accent-700);
    --vs-theme-parent-800: var(--vs-accent-800);
    --vs-theme-parent-900: var(--vs-accent-900);
  }
  .theme-accent-inherit {
    --vs-theme: var(--vs-theme-parent, var(--vs-accent));
    --vs-theme-50: var(--vs-theme-parent-50, var(--vs-accent-50));
    --vs-theme-100: var(--vs-theme-parent-100, var(--vs-accent-100));
    --vs-theme-200: var(--vs-theme-parent-200, var(--vs-accent-200));
    --vs-theme-300: var(--vs-theme-parent-300, var(--vs-accent-300));
    --vs-theme-400: var(--vs-theme-parent-400, var(--vs-accent-400));
    --vs-theme-500: var(--vs-theme-parent-500, var(--vs-accent-500));
    --vs-theme-600: var(--vs-theme-parent-600, var(--vs-accent-600));
    --vs-theme-700: var(--vs-theme-parent-700, var(--vs-accent-700));
    --vs-theme-800: var(--vs-theme-parent-800, var(--vs-accent-800));
    --vs-theme-900: var(--vs-theme-parent-900, var(--vs-accent-900));
  }

  // Info
  --vs-info: var(--vs-info-500);
  --vs-info-50: 231 247 254;
  --vs-info-100: 207 238 252;
  --vs-info-200: 159 221 249;
  --vs-info-300: 111 204 246;
  --vs-info-400: 63 187 243;
  --vs-info-500: 15 170 241;
  --vs-info-600: 12 136 193;
  --vs-info-700: 9 102 144;
  --vs-info-800: 6 68 96;
  --vs-info-900: 3 34 48;
  .theme-info {
    --vs-theme: var(--vs-info);
    --vs-theme-50: var(--vs-info-50);
    --vs-theme-100: var(--vs-info-100);
    --vs-theme-200: var(--vs-info-200);
    --vs-theme-300: var(--vs-info-300);
    --vs-theme-400: var(--vs-info-400);
    --vs-theme-500: var(--vs-info-500);
    --vs-theme-600: var(--vs-info-600);
    --vs-theme-700: var(--vs-info-700);
    --vs-theme-800: var(--vs-info-800);
    --vs-theme-900: var(--vs-info-900);
    --vs-theme-parent: var(--vs-info);
    --vs-theme-parent-50: var(--vs-info-50);
    --vs-theme-parent-100: var(--vs-info-100);
    --vs-theme-parent-200: var(--vs-info-200);
    --vs-theme-parent-300: var(--vs-info-300);
    --vs-theme-parent-400: var(--vs-info-400);
    --vs-theme-parent-500: var(--vs-info-500);
    --vs-theme-parent-600: var(--vs-info-600);
    --vs-theme-parent-700: var(--vs-info-700);
    --vs-theme-parent-800: var(--vs-info-800);
    --vs-theme-parent-900: var(--vs-info-900);
  }
  .theme-info-inherit {
    --vs-theme: var(--vs-theme-parent, var(--vs-info));
    --vs-theme-50: var(--vs-theme-parent-50, var(--vs-info-50));
    --vs-theme-100: var(--vs-theme-parent-100, var(--vs-info-100));
    --vs-theme-200: var(--vs-theme-parent-200, var(--vs-info-200));
    --vs-theme-300: var(--vs-theme-parent-300, var(--vs-info-300));
    --vs-theme-400: var(--vs-theme-parent-400, var(--vs-info-400));
    --vs-theme-500: var(--vs-theme-parent-500, var(--vs-info-500));
    --vs-theme-600: var(--vs-theme-parent-600, var(--vs-info-600));
    --vs-theme-700: var(--vs-theme-parent-700, var(--vs-info-700));
    --vs-theme-800: var(--vs-theme-parent-800, var(--vs-info-800));
    --vs-theme-900: var(--vs-theme-parent-900, var(--vs-info-900));
  }

  // Success
  --vs-success: var(--vs-success-500);
  --vs-success-50: 237 252 242;
  --vs-success-100: 212 248 225;
  --vs-success-200: 168 240 195;
  --vs-success-300: 125 233 164;
  --vs-success-400: 81 225 134;
  --vs-success-500: 38 218 104;
  --vs-success-600: 30 174 83;
  --vs-success-700: 23 131 62;
  --vs-success-800: 15 87 42;
  --vs-success-900: 8 44 21;
  .theme-success {
    --vs-theme: var(--vs-success);
    --vs-theme-50: var(--vs-success-50);
    --vs-theme-100: var(--vs-success-100);
    --vs-theme-200: var(--vs-success-200);
    --vs-theme-300: var(--vs-success-300);
    --vs-theme-400: var(--vs-success-400);
    --vs-theme-500: var(--vs-success-500);
    --vs-theme-600: var(--vs-success-600);
    --vs-theme-700: var(--vs-success-700);
    --vs-theme-800: var(--vs-success-800);
    --vs-theme-900: var(--vs-success-900);
    --vs-theme-parent: var(--vs-success);
    --vs-theme-parent-50: var(--vs-success-50);
    --vs-theme-parent-100: var(--vs-success-100);
    --vs-theme-parent-200: var(--vs-success-200);
    --vs-theme-parent-300: var(--vs-success-300);
    --vs-theme-parent-400: var(--vs-success-400);
    --vs-theme-parent-500: var(--vs-success-500);
    --vs-theme-parent-600: var(--vs-success-600);
    --vs-theme-parent-700: var(--vs-success-700);
    --vs-theme-parent-800: var(--vs-success-800);
    --vs-theme-parent-900: var(--vs-success-900);
  }
  .theme-success-inherit {
    --vs-theme: var(--vs-theme-parent, var(--vs-success));
    --vs-theme-50: var(--vs-theme-parent-50, var(--vs-success-50));
    --vs-theme-100: var(--vs-theme-parent-100, var(--vs-success-100));
    --vs-theme-200: var(--vs-theme-parent-200, var(--vs-success-200));
    --vs-theme-300: var(--vs-theme-parent-300, var(--vs-success-300));
    --vs-theme-400: var(--vs-theme-parent-400, var(--vs-success-400));
    --vs-theme-500: var(--vs-theme-parent-500, var(--vs-success-500));
    --vs-theme-600: var(--vs-theme-parent-600, var(--vs-success-600));
    --vs-theme-700: var(--vs-theme-parent-700, var(--vs-success-700));
    --vs-theme-800: var(--vs-theme-parent-800, var(--vs-success-800));
    --vs-theme-900: var(--vs-theme-parent-900, var(--vs-success-900));
  }

  // Warn
  --vs-warn: var(--vs-warn-500);
  --vs-warn-50: 254 245 231;
  --vs-warn-100: 253 236 206;
  --vs-warn-200: 251 216 157;
  --vs-warn-300: 249 197 108;
  --vs-warn-400: 247 177 59;
  --vs-warn-500: 245 158 10;
  --vs-warn-600: 196 126 8;
  --vs-warn-700: 147 95 6;
  --vs-warn-800: 98 63 4;
  --vs-warn-900: 49 32 2;
  .theme-warn {
    --vs-theme: var(--vs-warn);
    --vs-theme-50: var(--vs-warn-50);
    --vs-theme-100: var(--vs-warn-100);
    --vs-theme-200: var(--vs-warn-200);
    --vs-theme-300: var(--vs-warn-300);
    --vs-theme-400: var(--vs-warn-400);
    --vs-theme-500: var(--vs-warn-500);
    --vs-theme-600: var(--vs-warn-600);
    --vs-theme-700: var(--vs-warn-700);
    --vs-theme-800: var(--vs-warn-800);
    --vs-theme-900: var(--vs-warn-900);
    --vs-theme-parent: var(--vs-warn);
    --vs-theme-parent-50: var(--vs-warn-50);
    --vs-theme-parent-100: var(--vs-warn-100);
    --vs-theme-parent-200: var(--vs-warn-200);
    --vs-theme-parent-300: var(--vs-warn-300);
    --vs-theme-parent-400: var(--vs-warn-400);
    --vs-theme-parent-500: var(--vs-warn-500);
    --vs-theme-parent-600: var(--vs-warn-600);
    --vs-theme-parent-700: var(--vs-warn-700);
    --vs-theme-parent-800: var(--vs-warn-800);
    --vs-theme-parent-900: var(--vs-warn-900);
  }
  .theme-warn-inherit {
    --vs-theme: var(--vs-theme-parent, var(--vs-warn));
    --vs-theme-50: var(--vs-theme-parent-50, var(--vs-warn-50));
    --vs-theme-100: var(--vs-theme-parent-100, var(--vs-warn-100));
    --vs-theme-200: var(--vs-theme-parent-200, var(--vs-warn-200));
    --vs-theme-300: var(--vs-theme-parent-300, var(--vs-warn-300));
    --vs-theme-400: var(--vs-theme-parent-400, var(--vs-warn-400));
    --vs-theme-500: var(--vs-theme-parent-500, var(--vs-warn-500));
    --vs-theme-600: var(--vs-theme-parent-600, var(--vs-warn-600));
    --vs-theme-700: var(--vs-theme-parent-700, var(--vs-warn-700));
    --vs-theme-800: var(--vs-theme-parent-800, var(--vs-warn-800));
    --vs-theme-900: var(--vs-theme-parent-900, var(--vs-warn-900));
  }

  // Danger
  --vs-danger: var(--vs-danger-500);
  --vs-danger-50: 254 231 235;
  --vs-danger-100: 252 207 215;
  --vs-danger-200: 249 159 174;
  --vs-danger-300: 247 111 134;
  --vs-danger-400: 244 62 93;
  --vs-danger-500: 241 14 53;
  --vs-danger-600: 193 11 42;
  --vs-danger-700: 145 8 32;
  --vs-danger-800: 97 6 21;
  --vs-danger-900: 48 3 11;
  .theme-danger {
    --vs-theme: var(--vs-danger);
    --vs-theme-50: var(--vs-danger-50);
    --vs-theme-100: var(--vs-danger-100);
    --vs-theme-200: var(--vs-danger-200);
    --vs-theme-300: var(--vs-danger-300);
    --vs-theme-400: var(--vs-danger-400);
    --vs-theme-500: var(--vs-danger-500);
    --vs-theme-600: var(--vs-danger-600);
    --vs-theme-700: var(--vs-danger-700);
    --vs-theme-800: var(--vs-danger-800);
    --vs-theme-900: var(--vs-danger-900);
    --vs-theme-parent: var(--vs-danger);
    --vs-theme-parent-50: var(--vs-danger-50);
    --vs-theme-parent-100: var(--vs-danger-100);
    --vs-theme-parent-200: var(--vs-danger-200);
    --vs-theme-parent-300: var(--vs-danger-300);
    --vs-theme-parent-400: var(--vs-danger-400);
    --vs-theme-parent-500: var(--vs-danger-500);
    --vs-theme-parent-600: var(--vs-danger-600);
    --vs-theme-parent-700: var(--vs-danger-700);
    --vs-theme-parent-800: var(--vs-danger-800);
    --vs-theme-parent-900: var(--vs-danger-900);
  }
  .theme-danger-inherit {
    --vs-theme: var(--vs-theme-parent, var(--vs-danger));
    --vs-theme-50: var(--vs-theme-parent-50, var(--vs-danger-50));
    --vs-theme-100: var(--vs-theme-parent-100, var(--vs-danger-100));
    --vs-theme-200: var(--vs-theme-parent-200, var(--vs-danger-200));
    --vs-theme-300: var(--vs-theme-parent-300, var(--vs-danger-300));
    --vs-theme-400: var(--vs-theme-parent-400, var(--vs-danger-400));
    --vs-theme-500: var(--vs-theme-parent-500, var(--vs-danger-500));
    --vs-theme-600: var(--vs-theme-parent-600, var(--vs-danger-600));
    --vs-theme-700: var(--vs-theme-parent-700, var(--vs-danger-700));
    --vs-theme-800: var(--vs-theme-parent-800, var(--vs-danger-800));
    --vs-theme-900: var(--vs-theme-parent-900, var(--vs-danger-900));
  }

  // Gray
  --vs-gray: var(--vs-gray-500);
  --vs-gray-50: 248 250 252;
  --vs-gray-100: 241 245 249;
  --vs-gray-200: 226 232 240;
  --vs-gray-300: 203 213 225;
  --vs-gray-400: 148 163 184;
  --vs-gray-500: 100 116 139;
  --vs-gray-600: 71 85 105;
  --vs-gray-700: 51 65 85;
  --vs-gray-800: 30 41 59;
  --vs-gray-900: 15 23 42;
  .theme-default {
    --vs-theme: var(--vs-gray);
    --vs-theme-50: var(--vs-gray-50);
    --vs-theme-100: var(--vs-gray-100);
    --vs-theme-200: var(--vs-gray-200);
    --vs-theme-300: var(--vs-gray-300);
    --vs-theme-400: var(--vs-gray-400);
    --vs-theme-500: var(--vs-gray-500);
    --vs-theme-600: var(--vs-gray-600);
    --vs-theme-700: var(--vs-gray-700);
    --vs-theme-800: var(--vs-gray-800);
    --vs-theme-900: var(--vs-gray-900);
    --vs-theme-parent: var(--vs-gray);
    --vs-theme-parent-50: var(--vs-gray-50);
    --vs-theme-parent-100: var(--vs-gray-100);
    --vs-theme-parent-200: var(--vs-gray-200);
    --vs-theme-parent-300: var(--vs-gray-300);
    --vs-theme-parent-400: var(--vs-gray-400);
    --vs-theme-parent-500: var(--vs-gray-500);
    --vs-theme-parent-600: var(--vs-gray-600);
    --vs-theme-parent-700: var(--vs-gray-700);
    --vs-theme-parent-800: var(--vs-gray-800);
    --vs-theme-parent-900: var(--vs-gray-900);
  }
}
