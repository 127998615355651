@use '@angular/cdk';

@tailwind base;
@tailwind components;
@tailwind utilities;

// Default Swiper Styles
@import 'swiper/css';

@import '@vsolv/vectors-ui/theme';
@import '@vsolv/vectors-ui/scrollbar';

// CDK Overlay Styles
@import '@angular/cdk/overlay-prebuilt.css';
@include cdk.text-field-autosize();

/* You can add global styles to this file, and also import other style files */
$paletteColors: [ 'primary', 'accent', 'info', 'success', 'warn', 'danger'];

/* Button Styles */
@layer components {
  .vs-btn {
    // Button Styles
    @apply focus:ring-4 focus:outline-none font-medium rounded-lg px-5 py-2.5 text-center inline-flex items-center justify-center gap-2;

    &[size='small'] {
      @apply text-sm;
    }

    &[size='extra-small'] {
      @apply text-xs py-2;
    }

    &[size='large'] {
      @apply text-lg;
    }

    &[appearance='default'],
    &:not([appearance]) {
      @apply text-gray-700 hover:bg-gray-100 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800;
      @each $color in $paletteColors {
        &[color='#{$color}'] {
          @apply text-#{$color}-700 hover:bg-#{$color}-100 focus:ring-#{$color}-300 dark:bg-#{$color}-600 dark:hover:bg-#{$color}-700 dark:focus:ring-#{$color}-800;
        }
      }
    }

    &[appearance='fill'] {
      @apply text-base bg-gray-600 hover:bg-gray-700 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800;
      @each $color in $paletteColors {
        &[color='#{$color}'] {
          @apply text-base bg-#{$color}-600 hover:bg-#{$color}-700 focus:ring-#{$color}-300 dark:bg-#{$color}-600 dark:hover:bg-#{$color}-700 dark:focus:ring-#{$color}-800;
        }
      }

      &[disabled] {
        @apply cursor-not-allowed bg-gray-200;
        @each $color in $paletteColors {
          &[color='#{$color}'] {
            @apply bg-#{$color}-200;
          }
        }
      }
    }

    &[appearance='outline'] {
      @apply text-gray-700 border border-gray-700 hover:bg-gray-700 hover:text-base-white focus:ring-gray-300 dark:border-gray-500 dark:text-gray-500 dark:hover:text-base-white dark:focus:ring-gray-800;

      @each $color in $paletteColors {
        &[color='#{$color}'] {
          @apply text-#{$color}-700 border border-#{$color}-700 hover:bg-#{$color}-700 hover:text-base-white focus:ring-#{$color}-300 dark:border-#{$color}-500 dark:text-#{$color}-500 dark:hover:text-base-white dark:focus:ring-#{$color}-800;
        }
      }
    }
  }
}

/* Card styles */
@layer components {
  .vs-card {
    @apply block w-full rounded-lg;

    &[appearance='default'],
    &:not([appearance]) {
      @apply bg-gray-50  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700;
      @each $color in $paletteColors {
        &[color='#{$color}'] {
          @apply bg-#{$color}-100  dark:bg-#{$color}-800 dark:border-#{$color}-700 dark:hover:bg-#{$color}-700;
        }
      }
    }

    &[appearance='raised'] {
      @apply bg-base border border-gray-200 shadow-sm dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700;
      @each $color in $paletteColors {
        &[color='#{$color}'] {
          @apply bg-#{$color}-100 border border-#{$color}-200 shadow-sm dark:bg-#{$color}-800 dark:border-#{$color}-700 dark:hover:bg-#{$color}-700;
        }
      }
    }

    .vs-card-content {
      @apply px-5 py-3;
    }

    .vs-card-header {
      @apply text-lg font-bold tracking-tight text-gray-900 dark:text-base-white;
    }
  }
}

/* Table styles */
@layer components {
  .vs-table {
    @apply w-full text-sm text-left text-gray-500 dark:text-gray-400;

    thead {
      @apply text-xs text-gray-500 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-500 border-b dark:border-gray-700;

      th {
        @apply py-3 px-6;
        &:first-of-type {
          @apply rounded-tl-lg;
        }
        &:last-of-type {
          @apply rounded-tr-lg;
        }
      }
    }

    tbody {
      tr {
        @apply bg-base dark:bg-gray-900 hover:bg-gray-50 cursor-pointer active:bg-gray-100;

        &:last-of-type {
          td,
          th {
            &:first-child {
              @apply rounded-bl-lg;
            }
            &:last-child {
              @apply rounded-br-lg;
            }
          }
        }

        td,
        th {
          @apply py-3 px-6 text-gray-900 dark:text-base-white;
        }
      }
    }
  }
}

/* Input (w/ prefix) styles */
@layer components {
  .vs-input-group__prefix {
    @apply flex absolute inset-y-0 left-0 items-center pl-4 font-bold text-sm pointer-events-none text-gray-500 dark:text-gray-400;
  }

  .vs-input-group__input {
    @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg placeholder:italic focus:ring-primary-500 focus:border-primary-500 block w-full pl-16 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-base-white dark:focus:ring-primary-500 dark:focus:border-primary-500;
  }
}

/* Alert styles */
@layer components {
  .vs-alert-header {
    @apply flex items-center;

    .vs-alert-header-icon {
      @apply mr-2 w-5 h-5 text-info-700 dark:text-info-300;
    }

    .vs-alert-info-title {
      @apply text-lg font-medium text-info-700 dark:text-info-800;
    }
  }

  .vs-alert-info-content {
    @apply mt-2 mb-4 text-sm text-info-700 dark:text-info-800;
  }
}

/* Carousel styles */
@layer components {
  .vs-carousel-wrapper {
    @apply overflow-hidden relative h-56 rounded-lg md:h-96;
  }

  .vs-carousel-button-row {
    @apply flex flex-row justify-center mt-2;
  }

  .vs-carousel-button-container {
    @apply inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-base-dark/40 dark:bg-gray-800/30 group-hover:bg-base-dark/80 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-base dark:group-focus:ring-gray-800/70 group-focus:outline-none;
  }

  .vs-carousel-button {
    @apply flex justify-center mx-3 items-center h-full cursor-pointer focus:outline-none;
  }

  .vs-carousel-button-arrow {
    @apply w-5 h-5 text-base-white sm:w-6 sm:h-6 dark:text-gray-800;
  }

  .vs-carousel-icon {
    @apply w-3 h-3 rounded-full bg-base-dark/20 mx-1;
  }

  .vs-carousel-icons-container {
    @apply flex justify-center items-center;
  }
}

/* Vertical Timeline styles */
@layer components {
  .vs-vertical-timeline-wrapper {
    @apply relative border-l border-gray-200 dark:border-gray-700 ml-3;
  }

  .vs-timeline-item-container {
    @apply ml-6 pb-8;
  }

  .vs-timeline-item-logo-container {
    margin-top: 2px;
    @apply flex absolute -left-3 justify-center items-center w-6 h-6 bg-primary-200 rounded-full ring-8 ring-base dark:ring-gray-900 dark:bg-primary-900;

    > .vs-timeline-item-logo,
    > .vs-timeline-item-logo svg {
      // width: 0.85rem;
      // height: 0.85rem;
      @apply w-4 h-4 text-primary-700 dark:text-primary-400;
    }

    @each $color in $paletteColors {
      &[color='#{$color}'] {
        @apply bg-#{$color}-200 dark:bg-#{$color}-900;
        > .vs-timeline-item-logo,
        > .vs-timeline-item-logo svg {
          @apply text-#{$color}-700 dark:text-#{$color}-400;
        }
      }
    }
  }

  .vs-timeline-item-header {
    @apply flex text-gray-900 items-center mb-1 font-semibold;
  }

  .vs-timeline-item-date {
    @apply block text-sm font-normal leading-none text-gray-400 dark:text-gray-500;
  }

  .vs-timeline-item-content {
    @apply mt-3 text-sm font-normal text-gray-500 dark:text-gray-400;
  }
}

/* Modal Styles */
@layer components {
  .vs-modal-close-btn {
    @apply text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-base-white;
  }
  .vs-default-modal {
    @apply hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full;
  }
  .vs-modal-header {
    @apply flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600;
  }
  .vs-modal-body {
    @apply p-6 space-y-6;
  }
  .vs-modal-footer {
    @apply flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600;
  }
  .vs-modal-content {
    @apply relative bg-base rounded-lg shadow dark:bg-gray-700;
  }
  .vs-modal-container {
    @apply relative p-4 w-full max-w-2xl h-full md:h-auto;
  }
}

// Badge style large
@layer components {
  .vs-badge {
    @apply whitespace-nowrap bg-gray-100 text-gray-600 text-xs font-semibold px-2 py-1 rounded-md dark:bg-gray-200 dark:text-gray-700 w-fit;

    @each $color in $paletteColors {
      &[color='#{$color}'] {
        @apply bg-#{$color}-100 text-#{$color}-600 text-xs font-semibold px-2 py-1 rounded-md dark:bg-#{$color}-200 dark:text-#{$color}-700;
      }
    }

    &[size='large'] {
      @apply text-sm font-medium px-3 py-1 rounded-md;
    }

    &.clickable {
      @apply cursor-pointer hover:bg-gray-200 focus:ring-2 focus:outline-none focus:ring-gray-50;

      @each $color in $paletteColors {
        &[color='#{$color}'] {
          @apply hover:bg-#{$color}-200 focus:ring-#{$color}-500;
        }
      }
    }
  }
}

//Bread Crumb styles

@layer components {
  .vs-breadcrumb-parent {
    @apply inline-flex items-center;
  }

  .vs-breadcrumb-activeChild {
    @apply text-xs font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-base-white;
  }

  .vs-breadcrumb-child {
    @apply text-xs font-medium text-gray-500 dark:text-gray-400;
  }
}

// Select
@layer components {
  .vs-select {
    @apply focus:ring-4 focus:outline-none rounded-lg px-4 text-sm py-2 text-center inline-flex items-center;
    @apply text-gray-900 border border-gray-300 hover:border-gray-500 focus:border-gray-500 focus:ring-gray-300 disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none;

    @each $color in $paletteColors {
      &[color='#{$color}'] {
        @apply text-gray-900 border border-gray-300 hover:border-#{$color}-500 focus:border-#{$color}-500 focus:ring-#{$color}-300;
      }
    }
  }

  .vs-select-card {
    @apply mt-2 z-10 min-w-[320px] bg-base rounded divide-y divide-gray-100 shadow dark:bg-gray-700;
  }

  .vs-select-card-header {
    @apply mx-4 my-2 text-xs font-medium;
  }

  .vs-select-card-list {
    @apply py-1 text-sm text-gray-700 dark:text-gray-200;
  }

  .vs-select-card-list-item {
    @apply block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-base;
  }
}
